import netteForms from 'nette-forms';
netteForms.initOnLoad();
import 'plugins/cookie-bar/cookie-bar-naja.js';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import simpleLightbox from "simplelightbox/dist/simple-lightbox.jquery";

$(document).ready(function () {
    window.app = new App();
    window.app.init();
});

function LoaderExtension(naja) {
    naja.addEventListener('init', function () {
        window.app = new App();
        window.app.init();
    }.bind(this));

    naja.addEventListener('complete', hideLoader.bind(this));
    naja.addEventListener('complete', hideLoader.bind(this));

    function hideLoader() {
        window.app.reload();
    }

    return this;
}

var App = function () {
};

App.prototype.init = function () {
    var self = this;


    naja.initialize();
    naja.addEventListener('complete', hideLoader.bind(this));

    function hideLoader() {
        window.app.reload();
    }

    self.reload();
    this.initWebStaff();
};

App.prototype.initPagination = function () {
    if ($('.pagination').length) {
        //když .pagination a click tak animated scroll to .editor_content
        $('.pagination a').click(function () {
            $('html, body').animate({
                scrollTop: $('.breadcrumb').offset().top
            }, 1000);
        });
    }
};

App.prototype.replaceSmallWords = function () {
    const walker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);

    while (walker.nextNode()) {
        const node = walker.currentNode;

        // Nahrazení malých slov (1–2 písmena) v čistém textovém uzlu
        node.textContent = node.textContent.replace(/\s(\b\w{1,2}\b)\s/g, " $1\u00A0");
    }
}

App.prototype.initWebStaff = function () {
this.replaceSmallWords();

    const swiper = new Swiper('.hp_news__slider', {
        modules: [Navigation],
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.news_swiper-button-next',
            prevEl: '.news_swiper-button-prev',
        },
        breakpoints: {
            769: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    });

    const swiperSponsors = new Swiper('.sponsors__slider', {
        modules: [Navigation],
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.sponsors_swiper-button-next',
            prevEl: '.sponsors_swiper-button-prev',
        },
        breakpoints: {
            769: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1400: {
                slidesPerView: 4,
            }
        }

    });

    const mainslider = new Swiper('.main_slider', {
        loop: true,
        slidesPerView: 1,
    });

    const mainslider__text = new Swiper('.main_slider__text', {
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });


    var lightbox = $('.gallery a').simpleLightbox();
    $('.gallery-init a').simpleLightbox();

    // mainslider.controller.control = mainslider__text;
    // mainslider__text.controller.control = mainslider;

    // menu toggelr
    $('.menu_toggler').click(function () {
        $(this).toggleClass("active");
        $(".menu_toggler .menu_icon").toggleClass("open");
        $(".main_menu").slideToggle();
        $(".hp_menu").toggleClass("active");
    })

};

App.prototype.reload = function () {
    this.initPagination();
};
