class CookieBar {
    constructor() {
        this.elAppWrapper = document.querySelector('#cookie-wrapper');
        this.elBar = document.querySelector('#cookie-bar');
        this.elBarBg = document.querySelector('#cookie-bar-bg');
        this.elShowSettingsBtn = document.querySelector('.cookie-bar-settings-button');
        this.elSettingsBg = document.querySelector('#cookie-settings-bg');
        this.elSettings = document.querySelector('#cookie-settings');
        this.elSettingsContainer = document.querySelector('.cookie-settings-popup-container');
        this.elCheckbox = document.querySelector('.cookie-settings-toggle-switch-input');
        this.elAllowAllBtn = document.querySelector('.cookie-btn-allow-all');
        this.elSetSettingsBtn = document.querySelector('.cookie-btn-set-settings');
        this.elDeclineAllBtn = document.querySelector('.cookie-btn-decline-all-inner-link');
        this.elSettingsChoice = document.querySelector('.cookie-settings-choice');
        this.elSettingsText = document.querySelector('.cookie-settings-type-text');
        this.elShowMoreBtn = document.querySelector('.show-more');
        this.elShowLessBtn = document.querySelector('.show-less');
        this.elCloseBtn = document.querySelector('.cookie-setting-close');
        this.elOpenSettingsLink = document.querySelector('.cookie-link-open-settings');
        this.elAnalyticalCookiesAllowed = document.querySelector('#analyticalCookiesAllowed');
        this.elPersonalizedCookiesAllowed = document.querySelector('#personalizedCookiesAllowed');
        this.elMarketingCookiesAllowed = document.querySelector('#marketingCookiesAllowed');
    }

    init() {
        this.initOpenSettingsLink();
        if (this.elShowSettingsBtn) {
            this.elShowSettingsBtn.addEventListener('click', () => this.openSettings());
        }
        this.elAllowAllBtn.addEventListener('click', () => this.allowAll());
        this.elSetSettingsBtn.addEventListener('click', () => this.setSettings());
        if (this.elDeclineAllBtn) {
            this.elDeclineAllBtn.querySelector('a').addEventListener('click', (e) => this.declineAll(e));
        }
        this.elShowMoreBtn.addEventListener('click', () => this.showMore());
        this.elShowLessBtn.addEventListener('click', () => this.showLess());
    }

    initOpenSettingsLink() {
        if (this.elOpenSettingsLink) {
            this.elOpenSettingsLink.addEventListener('click', (e) => {
                e.preventDefault();
                this.openSettings();
            });
        }
    }

    openSettings() {
        this.showSettings();
        this.elCloseBtn.addEventListener('click', () => this.closeSettings());
        this.elSettingsBg.addEventListener('click', () => this.closeSettings());
        this.elSettings.addEventListener('click', (e) => {
            if (e.target.id === this.elSettings.id) {
                this.closeSettings();
            }
        });
    }

    showSettings() {
        this.elSettingsBg.style.display = 'flex';
        this.elSettings.style.display = 'flex';
        window.scrollTo(0, 0);
    }

    closeSettings() {
        if (this.elSettingsBg) {
            this.elSettingsBg.style.display = 'none';
        }
        if (this.elSettings) {
            this.elSettings.style.display = 'none';
        }
    }

    allowAll() {
        this.closeBar();
        this.elCheckbox.checked = true;
        naja.makeRequest('GET', this.elAllowAllBtn.dataset.link, null, { history: false })
            .catch(() => {
                // alert('CookieBar ajax not working.');
            });
    }

    setSettings() {
        const settings = [];
        if (this.elAnalyticalCookiesAllowed?.checked) {
            settings['analyticsCookiesEnabled'] = 1;
        }
        if (this.elPersonalizedCookiesAllowed?.checked) {
            settings['personalisationCookiesEnabled'] = 1;
        }
        if (this.elMarketingCookiesAllowed?.checked) {
            settings['marketingCookiesEnabled'] = 1;
        }
        const query = this.arrayToParams(settings);
        this.closeBar();
        naja.makeRequest(
            'GET',
            this.elSetSettingsBtn.closest('.cookie-btn-set-settings').dataset.link +
            (query.length ? '&' + query : ''),
            null,
            { history: false }
        ).catch(() => {
            // alert('CookieBar ajax not working.');
        });
    }

    declineAll(e) {
        e.preventDefault();
        this.closeBar();
        this.elCheckbox.checked = false;
        naja.makeRequest('GET', this.elDeclineAllBtn.closest('.cookie-btn-decline-all-inner-link').dataset.link, null, { history: false })
            .catch(() => {
                // alert('CookieBar ajax not working.');
            });
    }

    showMore() {
        this.elSettingsChoice.querySelector('.cookie-settings-type-text').style.maxHeight = '1000px';
        setTimeout(() => {
            this.elSettingsChoice.querySelector('.cookie-settings-type-text').style.maxHeight = 'initial';
        }, 1000);
        this.elSettingsChoice.querySelector('.show-less').classList.remove('hide');
        this.elShowMoreBtn.classList.add('hide');
    }

    showLess() {
        this.elSettingsChoice.querySelector('.cookie-settings-type-text').style.maxHeight = '40px';
        this.elSettingsChoice.querySelector('.show-more').classList.remove('hide');
        this.elShowLessBtn.classList.add('hide');
    }

    closeBar() {
        if (this.elBar) {
            this.elBar.style.display = 'none';
        }
        if (this.elBarBg) {
            this.elBarBg.style.display = 'none';
        }
        this.closeSettings();
    }

    arrayToParams(object) {
        return Object.keys(object)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(object[key]))
            .join('&');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const cookieBar = new CookieBar();
    if (document.querySelector('.cookie-link-open-settings') || document.querySelector('#cookie-bar')) {
        cookieBar.init();
    }
});